import React from 'react';
import { SearchInput } from '../../../components/search-input/search-input';
import './DefaultPageHeader.scss';

interface IProps {
  label: string;
  handleSearch?: (value: string) => void;
  notes?: string;
  dataTestId?: string;
}

const DefaultPageHeader: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { label, handleSearch, notes, dataTestId } = props;

  return (
    <>
      <div className='default-page-header'>
        <h1 className='default-page-title font-size-40px'>{label}</h1>
        {handleSearch && (
          <SearchInput
            onChange={handleSearch}
            withDebounce
            withResetSearchButton
          />
        )}
      </div>
      {notes && <div className='pb-8 px-8 word-break' dangerouslySetInnerHTML={{ __html: notes }} data-test-id={dataTestId} />}
      <hr/>
    </>
  );
};

export default DefaultPageHeader;
