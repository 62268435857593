import React from 'react';
import { MeetingOrganizationDetailsListType } from "../../../lib/api";

interface IProps {
  orgs: MeetingOrganizationDetailsListType;
  organizationClassName?: string;
}

const MeetingPresentersList = (props: IProps): JSX.Element => {
  const {
    orgs,
    organizationClassName = ''
  } = props;

  return (
    <div className='font-size-12px word-break'>
      {!!orgs.length && orgs.flatMap(o => o.attendees?.map((a) =>
        <p className={organizationClassName ? organizationClassName : 'py-2'} key={a.attendeeId}>
          <span className='font-semibold'>{`${a.firstName} ${a.lastName}`}</span>
          {a.title && (a.firstName || a.lastName) ? ', ' : ''}{a.title}, {o.name}
        </p>))}
    </div>
  );
};

export { MeetingPresentersList };
