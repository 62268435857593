import React from 'react';
import Button from '../../components/button';
import useConnectToPrivateMeetingMethod, { ConnectionPrivateDetailsType } from '../../hooks/use-connect-to-private-meeting-method';
import { ConnectionPublicDetailsType } from '../../hooks/use-connect-to-public-meeting-method';
import { PrivateMeetingDetailsResponseType, PublicMeetingDetailsResponseType } from '../../lib/api';
import { Connection } from '../../lib/connection/connection';

export type WatchButtonProps = {
  isDisabled: boolean;
  meetingDetails: PrivateMeetingDetailsResponseType | PublicMeetingDetailsResponseType | void;
  className?: string;
  joinUrl?: string | null;
}

export const WatchButton: React.FC<WatchButtonProps> = (props: WatchButtonProps): JSX.Element | null => {
  type ConnectionDetailsType = ConnectionPrivateDetailsType & ConnectionPublicDetailsType;

  const { isDisabled, meetingDetails, className, joinUrl } = props;
  const connectDetails = isDisabled ? undefined : { ...props.meetingDetails, joinUrl };
  const connectToMeeting = useConnectToPrivateMeetingMethod(connectDetails as ConnectionDetailsType);
  if (!meetingDetails) return null;

  const connection = Connection.create(meetingDetails);

  return (
    <Button.Common
      name='watch'
      disabled={isDisabled || (!connection && !joinUrl)}
      onClick={connectToMeeting}
      label='Watch'
      data-meeting-id={meetingDetails.meetingId}
      className={className}
    />
  );
};
