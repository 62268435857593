import React, { useLayoutEffect, useRef, useState } from 'react';
import useConnectMeetingDisabled from '../../../hooks/use-connect-meeting-disabled';
import { PrivateMeetingSessionDetailsResponseType } from '../../../lib/api';
import { REPLAY_TILE_LEFTOVER_CONTENT_HEIGHT } from '../../../lib/constants';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import { DateFnsFormats, dateToFormattedString, getIsEnUsRegion, getTimeInLocalFormat } from '../../../lib/helpers/dateHelper';
import MeetingDetailsLinksMapper from '../../shared/MeetingDetailsLinks/MeetingDetailsLinksMapper';
import { MoreButton } from '../../shared/MoreButton';
import { WatchButton } from '../../shared/WatchButton';
import { MeetingPresentersList } from '../SessionWrapper/MeetingPresenterList';
import { MeetingTopic } from '../SessionWrapper/MeetingTopic';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import { EventType } from '../SessionWrapper/LayoutWrapper';
import { PassportPageType, ReplaysPageType } from '../../../lib/api/custom-page.types';
import CardButtons from './CardButtons/CardButtons';

interface IProps {
  event: EventType;
  meeting: PrivateMeetingSessionDetailsResponseType;
  timeZone: string;
  isUpcomingSession?: boolean;
  isClosestMeeting?: boolean;
  isCarousel?: boolean;
}

const ReplaysMeetingTile = (props: IProps): JSX.Element => {
  const { event, meeting, timeZone, isUpcomingSession = false } = props;

  const {
    thumbnailImageUrl,
    presenterOrganizations,
    topic,
    featured,
    startDateTime,
    links = []
  } = meeting;

  const [contentExpanded, setContentExpanded] = useState(false);
  const [toggleVisible, setToggleVisible] = useState(false);

  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;

  const eventSettings = useEventSettings() as EventSettingsContextType;
  const isConnectBtnDisabled = useConnectMeetingDisabled(eventSettings, startDateTime);

  const fullDate = dateToFormattedString(
    startDateTime,
    getIsEnUsRegion()
      ? DateFnsFormats.StringifiedUSShortDay
      : DateFnsFormats.StringifiedEUShortDay, timeZone);

  let formattedHostStartTime = null;
  if (isUpcomingSession)
    formattedHostStartTime = getTimeInLocalFormat(startDateTime, timeZone);

  const contentRef = useRef(null);
  const expandableContentRef = useRef(null);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore*/
    const contentHeight = contentRef.current?.clientHeight || 0;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore*/
    const expandableContentHeight = expandableContentRef.current?.clientHeight || 0;

    if (expandableContentHeight > contentHeight - REPLAY_TILE_LEFTOVER_CONTENT_HEIGHT) {
      setContentExpanded(false);
      setToggleVisible(true);
    }
  }, []);

  const pageConfiguration = event.customPages.find(el => el.type === PassportPageType.REPLAYS) as ReplaysPageType;
  const preRegistrationEnabled = pageConfiguration.replaysPreRegistrationEnabled ;

  return (
    <div
      className={`replays-card 
      ${isUpcomingSession && !contentExpanded ? 'replays-card--upcoming' : ''} 
      ${contentExpanded ? ' replays-card--expanded' : ''} 
      ${!thumbnailImageUrl ? ' replays-card__image--empty' : ''}`}
    >
      {thumbnailImageUrl &&
        <div className='replays-card__img'>
          <img
            src={thumbnailImageUrl}
            alt='thumbnailImageUrl'
            title='thumbnailImageUrl'
          />
        </div>
      }
      <div ref={contentRef} className='replays-card__content'>
        <div
          ref={expandableContentRef}
          className='replays-card__expandable-content replays-card__content-inner'
        >
          <span title={fullDate} className='replays-card__content-date'>
            {fullDate} 
          </span>
          <span className='start-time'>{formattedHostStartTime}</span>
          <MeetingTopic
            orgs={presenterOrganizations ?? []}
            topic={topic}
            isFeatured={featured}
            classNames='replays-card__content-topic'
          />
          <MeetingPresentersList orgs={presenterOrganizations ?? []} organizationClassName='replays-card__presenters-list' />
          {links.length > 0 && <MeetingDetailsLinksMapper links={links} className='replays-card__links-mapper' dataTestId='replays-card-links-mapper' />}
        </div>
        <div className='replays-card__content-footer-buttons'>
          {toggleVisible &&
            <div className='replays-card__content-inner'>
              <MoreButton
                action={() => setContentExpanded(!contentExpanded)}
                isContentExpanded={contentExpanded}
                ariaLabel={'toggle content'}
                className='replays-card__content-more-button'
              />
            </div>
          }
          { isAuthenticated &&
                (isUpcomingSession ?
                  <CardButtons
                    event={event}
                    meeting={meeting}
                    preRegistrationEnabled={preRegistrationEnabled}
                    isFeatured={false}
                  />
                  : 
                  <div className='replays-card__content-inner'>
                    <WatchButton
                      isDisabled={isConnectBtnDisabled}
                      meetingDetails={meeting}
                      className='replays-card__watch-btn'
                    />
                  </div>
                ) }
        </div>
      </div>
    </div>
  );
};

export default ReplaysMeetingTile;
