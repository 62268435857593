import React, { createContext, ReactNode, useContext } from 'react';
import { useReplaysList } from '../../../hooks/api/protected/use-replays-list';
import { FilterReplays, ReplaysListResponseType } from '../../../lib/api';
import { PassportPageType, ReplaysPageType } from '../../../lib/api/custom-page.types';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';

interface IReplaysContext {
  eventData: EventSettingsContextType;
  pageConfiguration: ReplaysPageType;
  pastSessions: ReplaysListResponseType | void;
  upcomingSessions: ReplaysListResponseType | void;
  isValidatingPastSessions: boolean;
  isValidatingUpcomingSessions: boolean;
  upcomingSessionsRef: React.RefObject<HTMLHeadingElement>;
  pastSessionsRef: React.RefObject<HTMLHeadingElement>;
  scrollToRef: (ref: React.RefObject<HTMLHeadingElement>) => void;
}

const ReplaysContext = createContext<IReplaysContext | void>(undefined);

const ReplaysContextProvider = (props: { children: ReactNode }): React.ReactElement | null => {
  const upcomingSessionsRef: React.RefObject<HTMLHeadingElement> | null = React.useRef(null);
  const pastSessionsRef: React.RefObject<HTMLHeadingElement> | null = React.useRef(null);

  const scrollToRef = (ref: React.RefObject<HTMLHeadingElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  };

  const eventData = useEventSettings() as EventSettingsContextType;

  const pageConfiguration = eventData?.customPages.find(el => el.type === PassportPageType.REPLAYS) as ReplaysPageType;

  const { data: upcomingSessions, isValidating: isValidatingUpcomingSessions } = useReplaysList(String(eventData.eventId), FilterReplays.UpcomingReplays, pageConfiguration?.upcomingSessionsEnabled) as { data: ReplaysListResponseType | void, isValidating: boolean };
  const { data: pastSessions, isValidating: isValidatingPastSessions } = useReplaysList(String(eventData.eventId), FilterReplays.PastReplays) as { data: ReplaysListResponseType | void, isValidating: boolean };

  return (
    <ReplaysContext.Provider
      value={{
        eventData,
        pageConfiguration,
        pastSessions,
        upcomingSessions,
        isValidatingPastSessions,
        isValidatingUpcomingSessions,
        upcomingSessionsRef,
        pastSessionsRef,
        scrollToRef
      }}
    > 
      {props.children}
    </ReplaysContext.Provider>
  );
};

export const useReplaysContext = (): IReplaysContext | void => useContext(ReplaysContext);

export {
  ReplaysContext,
  ReplaysContextProvider
};

export type { IReplaysContext };
