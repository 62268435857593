import React from 'react';
import { downloadFileByUrl } from '../../lib/api/utils';
import { useAssetDownloadUrl } from '../../hooks/api/public/use-asset-download-url';

export const FileDownloadWrapper = (): JSX.Element => {
  const { data } = useAssetDownloadUrl();

  if (data?.signedUrl) downloadFileByUrl(data.signedUrl);

  return (
    <>
    </>
  );
};
