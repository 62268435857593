import React from 'react';
import DefaultPageHeader from '../../shared/DefaultPageHeader/DefaultPageHeader';
import { IReplaysContext, useReplaysContext } from './ReplaysContextProvider';
import ReplaysPastSessionsWrapper from './ReplaysPastSessionsWrapper';
import ReplaysUpcomingSessionsWrapper from './ReplaysUpcomingSessionsWrapper';

interface IProps {
  label?: string;
}

const ReplaysDetails: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const { label = '' } = props;

  const { eventData, pageConfiguration, upcomingSessions, pastSessions, isValidatingUpcomingSessions, isValidatingPastSessions } = useReplaysContext() as IReplaysContext;

  if ((upcomingSessions || pastSessions) && !isValidatingUpcomingSessions && !isValidatingPastSessions) {
    return (
      <div className={'shadow-gray bg-white mx-auto md:w-3/4 md:px-8 py-8 mt-12 max-h-full h-70 w-100 mx-0 px-0'}>
        <DefaultPageHeader label={label} notes={pageConfiguration.replaysNotes} dataTestId='replays-notes' />
        <div className='mt-4'>
          {pageConfiguration.upcomingSessionsEnabled &&
            <ReplaysUpcomingSessionsWrapper
              event={eventData}
              showAnchorElement={!!pastSessions && !!pastSessions.length}
              sessionLabel={pageConfiguration.upcomingSessionsLabel}
              pastSessionsLabel={pageConfiguration.replaysSessionLabel}
            />
          }
          <ReplaysPastSessionsWrapper
            event={eventData}
            showAnchorElement={!!upcomingSessions && !!upcomingSessions.length}
            sessionLabel={pageConfiguration.replaysSessionLabel}
            upcomingSessionsLabel={pageConfiguration.upcomingSessionsLabel}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default ReplaysDetails;
